import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useState } from "react";

import EditIcon from "../../assets/Icons/edit.svg";
import ProfileImg from "../../assets/img/profileImg.png";
import { LoginInput } from "../LoginInput/LoginInput";

import "./SettingProfile.scss";
import { useAppSelector } from "../../store/hooks";
import { selectAuthState } from "../../store/auth/selectors";
export type SettingProfileProps = {
  onClose: () => void;
};

export const SettingProfile = (props: SettingProfileProps) => {
  const { user } = useAppSelector(selectAuthState);
  const [name, setName] = useState(user?.name);
  return (
    <Stack className="profile">
      <Box className="profile-header">
        <Typography className="profile-title green" onClick={props.onClose}>
          Отменить
        </Typography>
        <Typography className="profile-title black">Мои Данные</Typography>
        <Typography className="profile-title gray" onClick={props.onClose}>
          Готово
        </Typography>
      </Box>
      <Box className="profile-image__container">
        <Avatar className="profile-image" src={user?.profileUrl || ""} />
        <img src={EditIcon} alt="edit" className="profile-image-edit" />
      </Box>
      <LoginInput
        name="Ваше имя"
        type="text"
        value={name || ""}
        onChange={(e) => setName(e)}
      />
    </Stack>
  );
};
