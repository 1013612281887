import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ListInternalModel } from "../../services/internalStorage/models/ListInternalModel";
import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import {
  ListItemInternalModel,
  ListItemUpdateParams,
} from "../../services/internalStorage/models/ListItemInternalModel";
import { InternalStorageCommonService } from "../../services/internalStorage/services/InternalStorageCommonService";
import { InternalStorageListsService } from "../../services/internalStorage/services/InternalStorageListsService";
import { ListDetailedDto, ListsService } from "../../services/openapi";
import {
  convertServerListItemCategoryToLocal,
  SyncListsService,
} from "../../services/sync/services/SyncListsService";
import { COMMON_STORE_KEYS } from "../../utils/constants";
import { getUniqueId } from "../../utils/dateTimeUtil";
import { addPrompt, updatePrompt } from "../prompts/promptsSlice";
import { RootState } from "../store";

export type ListsStateType = {
  lists: ListInternalModel[];
  listItems: ListItemInternalModel[];
  selectedList: ListInternalModel | null;
  shareListKeyForSharing: string | null;
  shareListKeyForAccept: string | null;
  listToAccept: ListDetailedDto | null;
  areListsLoading: boolean;
  areListItemsLoading: boolean;
};

const initialState: ListsStateType = {
  lists: [],
  listItems: [],
  selectedList: null,
  shareListKeyForSharing: null,
  shareListKeyForAccept: null,
  listToAccept: null,
  areListsLoading: true,
  areListItemsLoading: true,
};

export const syncLists = createAsyncThunk<void, void>("lists/syncLists", async () => {
  try {
    SyncListsService.enqueue();
  } catch (e) {
    console.log(e);
  }
});

export const fetchLists = createAsyncThunk<Array<ListInternalModel>>(
  "lists/fetchLists",
  async () => {
    try {
      return await InternalStorageListsService.getLists();
    } catch (e) {
      console.log(e);
      return [];
    }
  },
);

export const fetchListItems = createAsyncThunk<Array<ListItemInternalModel>, number>(
  "lists/fetchListItems",
  async (listId) => {
    try {
      return await InternalStorageListsService.getListItems(listId);
    } catch (e) {
      console.log(e);
      return [];
    }
  },
);

export const createList = createAsyncThunk<ListInternalModel | null, string>(
  "lists/createList",
  async (name) => {
    try {
      const currentDateISO = new Date().toISOString();
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        currentDateISO,
      );
      return await InternalStorageListsService.createList({
        name,
        id: null,
        localId: getUniqueId(),
        owner: { name: "", id: "" },
        order: 0,
        created: currentDateISO,
        updated: currentDateISO,
        deleted: null,
      });
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const removeList = createAsyncThunk<number | null, number>(
  "lists/removeList",
  async (listId) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      return await InternalStorageListsService.deleteList(listId, true);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const createListItem = createAsyncThunk<
  ListItemInternalModel | null,
  {
    name: string;
    localListId: number;
    localCategory: ListItemCategoryInternalModel | null;
  }
>("lists/createListItem", async ({ name, localListId, localCategory }) => {
  try {
    const currentDateISO = new Date().toISOString();
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      new Date().toISOString(),
    );
    return await InternalStorageListsService.createListItem({
      name: name,
      id: null,
      localId: getUniqueId(),
      localListId: localListId,
      isCompleted: false,
      created: currentDateISO,
      updated: currentDateISO,
      order: 0,
      deleted: null,
      localCategory,
    });
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const updateListItem = createAsyncThunk<void, ListItemUpdateParams>(
  "lists/updateListItem",
  async (listItemUpdateParams, { dispatch }) => {
    try {
      const updatedListItem =
        await InternalStorageListsService.updateListItem(listItemUpdateParams);
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      dispatch(fetchListItems(updatedListItem.localListId));
    } catch (e) {
      console.log(e);
    }
  },
);

export const changeListItemCategory = createAsyncThunk<
  void,
  { listItemLocalId: number; category: ListItemCategoryInternalModel | null }
>(
  "lists/changeListItemCategory",
  async ({ listItemLocalId, category }, { dispatch, getState }) => {
    try {
      dispatch(
        updateListItem({
          localId: listItemLocalId,
          localCategory: category,
        }),
      );
      const state = getState() as RootState;
      const listItem = state.lists.listItems.find(
        (listItem) => listItem.localId === listItemLocalId,
      );
      if (category && listItem) {
        const prompt = state.prompts.prompts.find(
          (prompt) => listItem.name === prompt.name,
        );
        if (prompt) {
          dispatch(
            updatePrompt({
              ...prompt,
              localCategoryId: category.localId,
              updated: new Date().toISOString(),
            }),
          );
        } else {
          dispatch(addPrompt({ name: listItem.name, localCategoryId: category.localId }));
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
);

export const deleteListItems = createAsyncThunk<
  number[] | null,
  { listItemsToDelete: Array<ListItemInternalModel> }
>("lists/deleteListItems", async ({ listItemsToDelete }) => {
  try {
    const deleteRequests = listItemsToDelete.map((listItem) =>
      InternalStorageListsService.deleteListItem(listItem.localId, true),
    );
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      new Date().toISOString(),
    );
    return await Promise.all(deleteRequests);
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const getShareListKey = createAsyncThunk<string | null, number>(
  "lists/getShareListKey",
  async (listId) => {
    try {
      return await ListsService.getApiListsShareKey(listId);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const getListByShareKey = createAsyncThunk<ListDetailedDto | null, string>(
  "lists/getListByShareKey",
  async (shareKey) => {
    try {
      return await ListsService.getApiListsShareKey1(shareKey);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const acceptSharedList = createAsyncThunk<
  ListInternalModel | null,
  {
    listToAccept: ListDetailedDto;
    shareListKeyForAccept: string;
    signedIn: boolean;
  }
>(
  "lists/acceptSharedList",
  async ({ listToAccept, shareListKeyForAccept, signedIn }, { dispatch }) => {
    try {
      if (signedIn) {
        await ListsService.postApiListsShared({ sharedListKey: shareListKeyForAccept });
        dispatch(syncLists());
        return null;
      } else {
        const acceptedList = await InternalStorageListsService.createList({
          name: listToAccept.name,
          id: null,
          localId: getUniqueId(),
          owner: listToAccept.owner ?? { name: "", id: "" },
          order: listToAccept.order,
          created: listToAccept.created ?? new Date().toISOString(),
          updated: listToAccept.updated ?? new Date().toISOString(),
          deleted: null,
        });
        if (listToAccept.items?.length) {
          await Promise.all(
            listToAccept.items.map(async (serverListItem) => {
              await InternalStorageListsService.createListItem({
                name: serverListItem.name,
                id: null,
                localId: getUniqueId(),
                localListId: acceptedList.localId,
                isCompleted: serverListItem.isCompleted,
                created: serverListItem.created || new Date().toISOString(),
                updated: serverListItem.updated || new Date().toISOString(),
                order: Infinity,
                deleted: null,
                localCategory: convertServerListItemCategoryToLocal(
                  serverListItem.category,
                ),
              });
            }),
          );
        }
        return acceptedList;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const deleteAllListsAndItems = createAsyncThunk<void, void>(
  "lists/deleteAllListsAndItems",
  async () => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      await InternalStorageListsService.deleteAllListsAndItems();
    } catch (e) {
      console.log(e);
    }
  },
);

export const runSyncLists = createAsyncThunk<void, number>(
  "lists/runSyncLists",
  async (interval, { dispatch, getState }) => {
    try {
      const updateStore = () => {
        dispatch(fetchLists());
        const state = getState() as RootState;
        if (state.lists.selectedList !== null) {
          dispatch(fetchListItems(state.lists.selectedList.localId));
        }
      };
      const checkSignIn = () => {
        const state = getState() as RootState;
        return state.auth.signedIn;
      };
      const getCurrentUserId = () => {
        const state = getState() as RootState;
        return state.auth.user?.id;
      };
      SyncListsService.run(interval, updateStore, checkSignIn, getCurrentUserId);
    } catch (e) {
      console.log(e);
    }
  },
);

export const stopSyncLists = createAsyncThunk<void, void>(
  "lists/stopSyncLists",
  async () => {
    try {
      SyncListsService.stop();
    } catch (e) {
      console.log(e);
    }
  },
);

export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    selectList(state, action: { payload: { localId: number } }) {
      const newSelectedList = state.lists.find(
        (l) => l.localId === action.payload.localId,
      );
      if (newSelectedList) {
        state.selectedList = { ...newSelectedList };
      }
    },
    setShareListKeyForAccept(state, action: { payload: string }) {
      if (action.payload) {
        state.shareListKeyForAccept = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLists.fulfilled, (state, action) => {
      const lists = action.payload
        .filter((l) => l.deleted === null)
        .sort((a, b) => a.order - b.order);
      state.lists = lists;
      if (lists.length > 0) {
        const updatedSelectedList = lists.find(
          (l) => l.localId === state.selectedList?.localId,
        );
        if (updatedSelectedList === undefined) {
          state.selectedList = { ...lists[0] };
        } else {
          state.selectedList = updatedSelectedList;
        }
      } else {
        state.selectedList = null;
        state.listItems = [];
      }
      state.areListsLoading = false;
    });
    builder.addCase(fetchListItems.fulfilled, (state, action) => {
      state.listItems = action.payload.filter((li) => li.deleted === null);
      state.areListItemsLoading = false;
    });
    builder.addCase(createList.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.lists.push(action.payload);
        state.selectedList = action.payload;
      }
    });
    builder.addCase(removeList.fulfilled, (state, action) => {
      state.lists = state.lists.filter((list) => list.localId !== action.payload);
      state.selectedList = state.lists.length ? state.lists[0] : null;
    });
    builder.addCase(createListItem.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.listItems.push(action.payload);
      }
    });
    builder.addCase(deleteListItems.fulfilled, (state, action) => {
      if (action.payload !== null) {
        const idsSet = new Set(action.payload);
        state.listItems = state.listItems.filter(
          (listItem) => !idsSet.has(listItem.localId),
        );
      }
    });
    builder.addCase(getShareListKey.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.shareListKeyForSharing = action.payload;
      }
    });
    builder.addCase(getListByShareKey.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.listToAccept = action.payload;
      }
    });
    builder.addCase(acceptSharedList.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.lists.push(action.payload);
        state.selectedList = action.payload;
      }
    });
    builder.addCase(deleteAllListsAndItems.fulfilled, (state) => {
      state.lists = [];
      state.listItems = [];
      state.selectedList = null;
    });
  },
});

export const { selectList, setShareListKeyForAccept } = listsSlice.actions;
