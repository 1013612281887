import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { useSpeechRecognition } from "react-speech-recognition";

import config from "../appConfig/config";
import { InstallPwaDialog } from "../components/Dialogs/InstallPwaDialog/InstallPwaDialog";
import { YandexMetrika } from "../components/YandexMetrika/YandexMetrika";
import { toggleIsOnline } from "../store/application/applicationSlice";
import { checkSignIn, getAndSaveUserProfile } from "../store/auth/authSlice";
import { selectAuthState } from "../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getDesignTokens } from "../theme";
import { Paths } from "../utils/constants";

import { FutureFeaturesPage } from "./pages/FutureFeaturesPage/FutureFeaturesPage";
import { ListsPage } from "./pages/ListsPage/ListsPage";
import { LoginHomePage } from "./pages/LoginHomePage/LoginHomePage";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { SettingsPage } from "./pages/SettingsPage/SettingsPage";
import { SignUpPage } from "./pages/SignUpPage/SignUpPage";
import { SupportPage } from "./pages/SupportPage/SupportPage";

export const App = () => {
  const dispatch = useAppDispatch();
  const { signedIn } = useAppSelector(selectAuthState);
  const { browserSupportsSpeechRecognition } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn&apos;t support speech recognition.</span>;
  }

  useEffect(() => {
    dispatch(checkSignIn());

    window.addEventListener("offline", () => dispatch(toggleIsOnline(false)));
    window.addEventListener("online", () => dispatch(toggleIsOnline(true)));
    return () => {
      window.removeEventListener("offline", () => dispatch(toggleIsOnline(false)));
      window.removeEventListener("online", () => dispatch(toggleIsOnline(true)));
    };
  }, []);

  useEffect(() => {
    if (signedIn) {
      dispatch(getAndSaveUserProfile());
    }
  }, [signedIn]);

  const theme = useMemo(() => createTheme(getDesignTokens("light")), []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GoogleOAuthProvider clientId={config.GoogleApiClientId}>
        <Routes>
          <Route path="/" element={<ListsPage />} />
          <Route path={Paths.SUPPORT} element={<SupportPage />} />
          <Route path={Paths.LOGIN_HOME} element={<LoginHomePage />} />
          <Route path={Paths.LOGIN} element={<LoginPage />} />
          <Route path={Paths.SIGN_UP} element={<SignUpPage />} />
          <Route path={Paths.FUTURE_FEATURES} element={<FutureFeaturesPage />} />
          <Route path={Paths.SETTINGS} element={<SettingsPage />} />
        </Routes>
        <InstallPwaDialog
          signedIn={signedIn}
          interval={config.ShowInstallButtonInterval}
          delay={config.ShowInstallButtonDelay}
        />
      </GoogleOAuthProvider>
      <YandexMetrika code={config.YandexMetrikaCode} />
    </ThemeProvider>
  );
};
