import { COMMON_STORE_KEYS } from "../../../utils/constants";
import { getDateValue, getUniqueId } from "../../../utils/dateTimeUtil";
import { ListInternalModel } from "../../internalStorage/models/ListInternalModel";
import { ListItemCategoryInternalModel } from "../../internalStorage/models/ListItemCategoryInternalModel";
import { ListItemInternalModel } from "../../internalStorage/models/ListItemInternalModel";
import { InternalStorageCategoriesService } from "../../internalStorage/services/InternalStorageCategoriesService";
import { InternalStorageCommonService } from "../../internalStorage/services/InternalStorageCommonService";
import { InternalStorageListsService } from "../../internalStorage/services/InternalStorageListsService";
import {
  CategoryDto,
  LastChangesService,
  ListDetailedDto,
  ListDto,
  ListItemCategoryDto,
  ListItemDto,
  ListsService,
  UserCategoriesService,
} from "../../openapi";

import { SequentialTaskRunner } from "./SequentialTaskRunner";

const isCategorySynced = (
  serverCategory: CategoryDto | undefined,
  localCategory: ListItemCategoryInternalModel | undefined,
) =>
  localCategory?.id &&
  serverCategory?.updated &&
  localCategory?.updated &&
  getDateValue(serverCategory.updated) === getDateValue(localCategory.updated);

const isListSynced = (
  serverList: ListDetailedDto | undefined,
  localList: ListInternalModel | undefined,
) =>
  localList?.id &&
  serverList?.updated &&
  localList?.updated &&
  getDateValue(serverList.updated) === getDateValue(localList.updated);

const getListAndCategoryOfServerListItem = (
  serverListItem: ListItemDto,
  serverLists: ListDetailedDto[],
  localLists: ListInternalModel[],
  serverCategories: CategoryDto[],
  localCategories: ListItemCategoryInternalModel[],
) => {
  const localList: ListInternalModel | undefined = localLists.find(
    (localList: ListInternalModel): boolean => localList.id === serverListItem.listId,
  );
  const serverList: ListDetailedDto | undefined = serverLists.find(
    (serverList: ListDetailedDto): boolean => serverList.id === serverListItem.listId,
  );
  const localCategory: ListItemCategoryInternalModel | undefined = localCategories.find(
    (localCategory: ListItemCategoryInternalModel): boolean =>
      localCategory.id === serverListItem.category?.id,
  );
  const serverCategory: CategoryDto | undefined = serverCategories.find(
    (serverCategory: CategoryDto): boolean =>
      serverCategory.id === serverListItem.category?.id,
  );
  return { localList, serverList, localCategory, serverCategory };
};

const getListAndCategoryOfLocalListItem = (
  localListItem: ListItemInternalModel,
  serverLists: ListDetailedDto[],
  localLists: ListInternalModel[],
  serverCategories: CategoryDto[],
  localCategories: ListItemCategoryInternalModel[],
) => {
  const localList: ListInternalModel | undefined = localLists.find(
    (localList: ListInternalModel): boolean =>
      localList.localId === localListItem.localListId,
  );
  const serverList: ListDetailedDto | undefined = serverLists.find(
    (serverList: ListDetailedDto): boolean => serverList.id === localList?.id,
  );
  const localCategory: ListItemCategoryInternalModel | undefined = localCategories.find(
    (localCategory: ListItemCategoryInternalModel): boolean =>
      localCategory.localId === localListItem.localCategory?.localId,
  );
  const serverCategory: CategoryDto | undefined = serverCategories.find(
    (serverCategory: CategoryDto): boolean => serverCategory.id === localCategory?.id,
  );
  return { localList, serverList, localCategory, serverCategory };
};

export const convertServerListItemCategoryToLocal = (
  serverCategory?: ListItemCategoryDto,
): ListItemCategoryInternalModel | null =>
  serverCategory
    ? {
        id: serverCategory.id,
        localId: getUniqueId(),
        name: serverCategory.name,
        color: serverCategory.color,
        colorDark: serverCategory.colorDark,
        order: serverCategory.order,
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
        deleted: null,
      }
    : null;

export class SyncListsService {
  private static intervalId: NodeJS.Timer | null = null;
  private static sequentialTaskRunner: SequentialTaskRunner = new SequentialTaskRunner();
  private static updateStore: (() => void) | undefined;
  private static checkSignIn: (() => boolean) | undefined;
  private static getCurrentUserId: (() => string | undefined) | undefined;

  private static async saveServerListsToLocal(
    serverLists: ListDetailedDto[],
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverLists.map(async (serverList: ListDetailedDto): Promise<void> => {
        const isOnLocal: boolean = localLists.some(
          (localList: ListInternalModel): boolean => localList.id === serverList.id,
        );
        if (!isOnLocal) {
          await InternalStorageListsService.createList({
            name: serverList.name,
            id: serverList.id,
            localId: getUniqueId(),
            owner: serverList.owner ?? { name: "", id: "" },
            order: serverList.order,
            created: serverList.created ?? new Date().toISOString(),
            updated: serverList.updated ?? new Date().toISOString(),
            deleted: null,
          });
        }
      }),
    );
  }

  private static async deleteServerLists(
    serverLists: ListDetailedDto[],
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverLists.map(async (serverList: ListDetailedDto): Promise<void> => {
        const isLocalVersionDeleted = localLists.some(
          (localList: ListInternalModel) =>
            localList.id === serverList.id && localList.deleted,
        );
        if (isLocalVersionDeleted) {
          await ListsService.deleteApiLists(serverList.id);
        }
      }),
    );
  }

  private static async postLocalListsToServer(
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localLists.map(async (localList: ListInternalModel): Promise<void> => {
        const wasOnServer = !!localList.id;
        const isDeleted = !!localList.deleted;
        if (!wasOnServer && !isDeleted) {
          const postResult: ListDto = await ListsService.postApiLists({
            name: localList.name,
            created: localList.created,
            updated: localList.updated,
          });
          await InternalStorageListsService.updateList({
            localId: localList.localId,
            id: postResult.id,
            name: postResult.name,
            order: postResult.order,
            owner: postResult.owner,
            created: postResult.created,
            updated: postResult.updated,
          });
        }
      }),
    );
  }

  private static async deleteLocalLists(
    serverLists: ListDetailedDto[],
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localLists.map(async (localList: ListInternalModel): Promise<void> => {
        const wasOnServer = !!localList.id;
        const isOnServer = serverLists.some(
          (serverList: ListDetailedDto): boolean => serverList.id === localList.id,
        );
        if (wasOnServer && !isOnServer) {
          await InternalStorageListsService.deleteList(localList.localId, false);
        }
      }),
    );
  }

  private static async cleanUpSoftDeletedLocalLists(
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localLists.map(async (localList: ListInternalModel): Promise<void> => {
        if (localList.deleted) {
          await InternalStorageListsService.deleteList(localList.localId, false);
        }
      }),
    );
  }

  private static async saveServerListItemsToLocal(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
    serverLists: ListDetailedDto[],
    localLists: ListInternalModel[],
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverListItems.map(async (serverListItem: ListItemDto): Promise<void> => {
        const { localList, serverList, localCategory, serverCategory } =
          getListAndCategoryOfServerListItem(
            serverListItem,
            serverLists,
            localLists,
            serverCategories,
            localCategories,
          );
        const isOnLocal: boolean = localListItems.some(
          (localListItem: ListItemInternalModel): boolean =>
            localListItem.id === serverListItem.id,
        );
        // const currentUserId = this.getCurrentUserId && this.getCurrentUserId();
        // const isListOurs = currentUserId && currentUserId === serverList?.owner?.id;
        const isCategoryOurs =
          serverListItem.category &&
          serverCategories.some(
            (c: CategoryDto): boolean => c.id === serverListItem.category?.id,
          );
        const shouldWaitCategorySync =
          isCategoryOurs && !isCategorySynced(serverCategory, localCategory);
        const shouldWaitListSync = !isListSynced(serverList, localList);

        if (!isOnLocal && !shouldWaitListSync && !shouldWaitCategorySync && localList) {
          await InternalStorageListsService.createListItem({
            name: serverListItem.name,
            id: serverListItem.id,
            localId: getUniqueId(),
            localListId: localList.localId,
            isCompleted: serverListItem.isCompleted,
            created: serverListItem.created || new Date().toISOString(),
            updated: serverListItem.updated || new Date().toISOString(),
            order: Infinity,
            deleted: null,
            localCategory:
              localCategory ??
              convertServerListItemCategoryToLocal(serverListItem.category) ??
              null,
          });
        }
      }),
    );
  }

  private static async deleteServerListItems(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverListItems.map(async (serverListItem: ListItemDto): Promise<void> => {
        const isLocalVersionDeleted = localListItems.some(
          (localListItem: ListItemInternalModel) =>
            localListItem.id === serverListItem.id && localListItem.deleted,
        );
        if (isLocalVersionDeleted) {
          await ListsService.deleteApiListsItem(serverListItem.listId, serverListItem.id);
        }
      }),
    );
  }

  private static async postLocalListItemsToServer(
    localListItems: ListItemInternalModel[],
    serverLists: ListDetailedDto[],
    localLists: ListInternalModel[],
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localListItems.map(async (localListItem: ListItemInternalModel): Promise<void> => {
        const { localList, serverList, localCategory, serverCategory } =
          getListAndCategoryOfLocalListItem(
            localListItem,
            serverLists,
            localLists,
            serverCategories,
            localCategories,
          );
        const wasOnServer = !!localListItem.id;
        const isDeleted = !!localListItem.deleted;
        // const currentUserId = this.getCurrentUserId && this.getCurrentUserId();
        // const isListOurs = currentUserId && currentUserId === serverList?.owner?.id;
        const isCategoryOurs =
          !!localListItem.localCategory &&
          localCategories.some(
            (c: ListItemCategoryInternalModel): boolean =>
              c.localId === localListItem.localCategory?.localId,
          );
        const shouldWaitCategorySync =
          isCategoryOurs && !isCategorySynced(serverCategory, localCategory);
        const shouldWaitListSync = !isListSynced(serverList, localList);

        if (
          !wasOnServer &&
          !isDeleted &&
          !shouldWaitListSync &&
          !shouldWaitCategorySync &&
          serverList
        ) {
          const postResult: ListItemDto = await ListsService.postApiListsItem(
            serverList.id,
            {
              name: localListItem.name,
              isCompleted: localListItem.isCompleted,
              categoryId: serverCategory?.id,
              created: localListItem.created,
              updated: localListItem.updated,
            },
          );
          await InternalStorageListsService.updateListItem({
            localId: localListItem.localId,
            id: postResult.id,
            name: postResult.name,
            isCompleted: postResult.isCompleted,
            created: postResult.created,
            updated: postResult.updated,
          });
        }
      }),
    );
  }

  private static async deleteLocalListItems(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localListItems.map(async (localListItem: ListItemInternalModel): Promise<void> => {
        const wasOnServer = !!localListItem.id;
        const isOnServer = serverListItems.some(
          (serverListItem: ListItemDto): boolean =>
            serverListItem.id === localListItem.id,
        );
        if (wasOnServer && !isOnServer) {
          await InternalStorageListsService.deleteListItem(localListItem.localId, false);
        }
      }),
    );
  }

  private static async updateLocalListItems(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
    serverLists: ListDetailedDto[],
    localLists: ListInternalModel[],
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localListItems.map(async (localListItem: ListItemInternalModel): Promise<void> => {
        const serverListItem: ListItemDto | undefined = serverListItems.find(
          (serverListItem: ListItemDto): boolean =>
            serverListItem.id === localListItem.id,
        );
        if (!serverListItem?.updated || !localListItem.updated) {
          return;
        }
        const { localList, serverList, localCategory, serverCategory } =
          getListAndCategoryOfServerListItem(
            serverListItem,
            serverLists,
            localLists,
            serverCategories,
            localCategories,
          );
        // const currentUserId = this.getCurrentUserId && this.getCurrentUserId();
        // const isListOurs = currentUserId && currentUserId === serverList?.owner?.id;
        const isCategoryOurs =
          serverListItem.category &&
          serverCategories.some(
            (c: CategoryDto): boolean => c.id === serverListItem.category?.id,
          );
        const shouldWaitCategorySync =
          isCategoryOurs && !isCategorySynced(serverCategory, localCategory);
        const shouldWaitListSync = !isListSynced(serverList, localList);
        const serverItemUpdated: number = getDateValue(serverListItem.updated);
        const localItemUpdated: number = getDateValue(localListItem.updated);
        const isServerItemNewer: boolean = serverItemUpdated - localItemUpdated > 0;

        if (
          isServerItemNewer &&
          !localListItem.deleted &&
          !shouldWaitCategorySync &&
          !shouldWaitListSync
        ) {
          await InternalStorageListsService.updateListItem({
            localId: localListItem.localId,
            isCompleted: serverListItem.isCompleted,
            localCategory:
              localCategory ??
              convertServerListItemCategoryToLocal(serverListItem.category) ??
              null,
            updated: serverListItem.updated,
          });
        }
      }),
    );
  }

  private static async updateServerListItems(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
    serverLists: ListDetailedDto[],
    localLists: ListInternalModel[],
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverListItems.map(async (serverListItem: ListItemDto): Promise<void> => {
        const localListItem: ListItemInternalModel | undefined = localListItems.find(
          (localListItem: ListItemInternalModel): boolean =>
            localListItem.id === serverListItem.id,
        );
        if (!localListItem?.updated || !serverListItem.updated) {
          return;
        }
        const { localList, serverList, localCategory, serverCategory } =
          getListAndCategoryOfLocalListItem(
            localListItem,
            serverLists,
            localLists,
            serverCategories,
            localCategories,
          );
        // const currentUserId = this.getCurrentUserId && this.getCurrentUserId();
        // const isListOurs = currentUserId && currentUserId === serverList?.owner?.id;
        const isCategoryOurs =
          !!localListItem.localCategory &&
          localCategories.some(
            (c: ListItemCategoryInternalModel): boolean =>
              c.localId === localListItem.localCategory?.localId,
          );
        const shouldWaitCategorySync =
          isCategoryOurs && !isCategorySynced(serverCategory, localCategory);
        const shouldWaitListSync = !isListSynced(serverList, localList);
        const localItemUpdated: number = new Date(localListItem.updated).valueOf();
        const serverItemUpdated: number = new Date(serverListItem.updated).valueOf();
        const isLocalItemNewer: boolean = localItemUpdated - serverItemUpdated > 0;

        if (
          isLocalItemNewer &&
          !localListItem.deleted &&
          !shouldWaitCategorySync &&
          !shouldWaitListSync
        ) {
          await ListsService.putApiListsItem(serverListItem.listId, serverListItem.id, {
            isCompleted: localListItem.isCompleted,
            categoryId: localCategory?.id ?? serverListItem.category?.id,
            updated: localListItem.updated,
          });
        }
      }),
    );
  }

  private static async cleanUpSoftDeletedLocalListItems(
    localListsItems: ListItemInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localListsItems.map(async (localListItem: ListItemInternalModel): Promise<void> => {
        if (localListItem.deleted) {
          await InternalStorageListsService.deleteListItem(localListItem.localId, false);
        }
      }),
    );
  }

  private static async syncLists(): Promise<void> {
    const serverLists: ListDetailedDto[] = await ListsService.getApiListsDetailed();
    const localLists: ListInternalModel[] = await InternalStorageListsService.getLists();
    await this.saveServerListsToLocal(serverLists, localLists);
    await this.postLocalListsToServer(localLists);
    await this.deleteLocalLists(serverLists, localLists);
    await this.deleteServerLists(serverLists, localLists);
    await this.cleanUpSoftDeletedLocalLists(localLists);
  }

  private static async syncListItems(): Promise<void> {
    const serverLists: ListDetailedDto[] = await ListsService.getApiListsDetailed();
    const localLists: ListInternalModel[] = await InternalStorageListsService.getLists();
    const serverListItems: ListItemDto[] = serverLists.flatMap(
      (list: ListDetailedDto) => list.items ?? [],
    );
    const localListItems: ListItemInternalModel[] =
      await InternalStorageListsService.getAllListItems();
    const serverCategories = await UserCategoriesService.getApiUserCategories();
    const localCategories = await InternalStorageCategoriesService.getCategories();
    await this.saveServerListItemsToLocal(
      serverListItems,
      localListItems,
      serverLists,
      localLists,
      serverCategories,
      localCategories,
    );
    await this.deleteLocalListItems(serverListItems, localListItems);
    await this.postLocalListItemsToServer(
      localListItems,
      serverLists,
      localLists,
      serverCategories,
      localCategories,
    );
    await this.deleteServerListItems(serverListItems, localListItems);
    await this.updateLocalListItems(
      serverListItems,
      localListItems,
      serverLists,
      localLists,
      serverCategories,
      localCategories,
    );
    await this.updateServerListItems(
      serverListItems,
      localListItems,
      serverLists,
      localLists,
      serverCategories,
      localCategories,
    );
    await this.cleanUpSoftDeletedLocalListItems(localListItems);
  }

  private static async isLoggedIn(): Promise<boolean> {
    return (this.checkSignIn && this.checkSignIn()) ?? false;
  }

  private static async isFrontendChanged(): Promise<boolean> {
    const frontendLastChange =
      ((await InternalStorageCommonService.getValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      )) as string | undefined) ?? new Date(0).toISOString();
    const lastSync =
      ((await InternalStorageCommonService.getValue(
        COMMON_STORE_KEYS.LISTS_LAST_SYNC,
      )) as string | undefined) ?? new Date(0).toISOString();
    return getDateValue(frontendLastChange) > getDateValue(lastSync);
  }

  private static async isBackendChanged(): Promise<boolean> {
    const backendLastChange = await LastChangesService.getApiChanges(0);
    const lastSync =
      ((await InternalStorageCommonService.getValue(
        COMMON_STORE_KEYS.LISTS_LAST_SYNC,
      )) as string | undefined) ?? new Date(0).toISOString();
    return getDateValue(`${backendLastChange}Z`) > getDateValue(lastSync);
  }

  public static enqueue(): void {
    this.sequentialTaskRunner.enqueue(async () => {
      const shouldSync =
        (await this.isLoggedIn()) &&
        ((await this.isFrontendChanged()) || (await this.isBackendChanged()));
      if (shouldSync) {
        console.time("Lists synced");
        console.log("Lists synchronization is running...");
        const beforeSyncTime = new Date().toISOString();
        await this.syncLists();
        await this.syncListItems();
        const frontendLastChange =
          ((await InternalStorageCommonService.getValue(
            COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
          )) as string | undefined) ?? new Date(0).toISOString();
        const timeToSet =
          getDateValue(frontendLastChange) > getDateValue(beforeSyncTime)
            ? beforeSyncTime
            : new Date().toISOString();
        await InternalStorageCommonService.addOrUpdateValue(
          COMMON_STORE_KEYS.LISTS_LAST_SYNC,
          timeToSet,
        );
        LastChangesService.postApiChanges({ entity: 0, dateTime: timeToSet });
        if (this.updateStore) {
          this.updateStore();
        }
        console.timeEnd("Lists synced");
      }
    });
  }

  public static run(
    interval: number,
    updateStore: () => void,
    checkSignIn: () => boolean,
    getCurrentUserId: () => string | undefined,
  ): void {
    if (!this.intervalId) {
      this.updateStore = updateStore;
      this.checkSignIn = checkSignIn;
      this.getCurrentUserId = getCurrentUserId;
      this.intervalId = setInterval(() => this.enqueue(), interval);
    }
  }

  public static stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.sequentialTaskRunner.clearQueue();
    }
  }
}
