import { COMMON_STORE_KEYS } from "../../../utils/constants";
import { getDateValue, getUniqueId } from "../../../utils/dateTimeUtil";
import { ListItemCategoryInternalModel } from "../../internalStorage/models/ListItemCategoryInternalModel";
import { InternalStorageCategoriesService } from "../../internalStorage/services/InternalStorageCategoriesService";
import { InternalStorageCommonService } from "../../internalStorage/services/InternalStorageCommonService";
import {
  CategoriesService,
  CategoryDto,
  LastChangesService,
  UserCategoriesService,
} from "../../openapi";

import { SequentialTaskRunner } from "./SequentialTaskRunner";

export class SyncCategoriesService {
  private static intervalId: NodeJS.Timer | null = null;
  private static sequentialTaskRunner = new SequentialTaskRunner();
  private static updateStore: (() => void) | undefined;
  private static checkSignIn: (() => boolean) | undefined;

  private static async saveServerCategoriesToLocal(
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await InternalStorageCategoriesService.addOrUpdateCategories(
      serverCategories
        .map((serverCategory: CategoryDto): ListItemCategoryInternalModel | undefined => {
          const isOnLocal: boolean = localCategories.some(
            (localCategory: ListItemCategoryInternalModel): boolean =>
              localCategory.id === serverCategory.id,
          );
          if (!isOnLocal) {
            return {
              id: serverCategory.id,
              localId: getUniqueId(),
              name: serverCategory.name,
              color: serverCategory.color,
              colorDark: serverCategory.colorDark,
              order: serverCategory.order,
              created: serverCategory.created,
              updated: serverCategory.updated,
              deleted: null,
            };
          }
        })
        .filter(
          (
            category: ListItemCategoryInternalModel | undefined,
          ): category is ListItemCategoryInternalModel => category !== undefined,
        ),
    );
  }

  private static async deleteServerCategories(
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverCategories.map(async (serverCategory: CategoryDto): Promise<void> => {
        const isLocalVersionDeleted = localCategories.some(
          (localCategory: ListItemCategoryInternalModel) =>
            localCategory.id === serverCategory.id && localCategory.deleted,
        );
        if (isLocalVersionDeleted) {
          await UserCategoriesService.deleteApiUserCategories(serverCategory.id);
          await InternalStorageCategoriesService.deleteCategoriesByLocalIds([
            serverCategory.id,
          ]);
        }
      }),
    );
  }

  private static async postLocalCategoriesToServer(
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localCategories.map(
        async (localCategory: ListItemCategoryInternalModel): Promise<void> => {
          const wasOnServer = !!localCategory.id;
          const isDeleted = !!localCategory.deleted;
          if (!wasOnServer && !isDeleted) {
            const postResult: CategoryDto =
              await UserCategoriesService.postApiUserCategories({
                name: localCategory.name,
                order: localCategory.order,
                color: localCategory.color,
                colorDark: localCategory.color,
              });
            await InternalStorageCategoriesService.addOrUpdateCategories([
              { ...localCategory, id: postResult.id },
            ]);
          }
        },
      ),
    );
  }

  private static async deleteLocalCategories(
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await InternalStorageCategoriesService.deleteCategoriesByLocalIds(
      localCategories
        .map((localCategory: ListItemCategoryInternalModel): number | undefined => {
          const wasOnServer = !!localCategory.id;
          const isOnServer = serverCategories.some(
            (serverCategory: CategoryDto): boolean =>
              serverCategory.id === localCategory.id,
          );
          if (wasOnServer && !isOnServer) {
            return localCategory.localId;
          }
        })
        .filter(
          (localId: number | undefined): localId is number => localId !== undefined,
        ),
    );
  }

  private static async updateLocalCategories(
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await InternalStorageCategoriesService.addOrUpdateCategories(
      localCategories
        .map(
          (
            localCategory: ListItemCategoryInternalModel,
          ): ListItemCategoryInternalModel | undefined => {
            const serverCategory: CategoryDto | undefined = serverCategories.find(
              (serverCategory: CategoryDto): boolean =>
                serverCategory.id === localCategory.id,
            );
            if (!serverCategory?.updated || !localCategory.updated) {
              return;
            }
            const serverCategoryUpdated: number = new Date(
              serverCategory.updated,
            ).valueOf();
            const localCategoryUpdated: number = new Date(
              localCategory.updated,
            ).valueOf();
            const isServerCategoryNewer: boolean =
              serverCategoryUpdated - localCategoryUpdated > 0;

            if (isServerCategoryNewer && !localCategory.deleted) {
              return {
                id: serverCategory.id,
                localId: localCategory.localId,
                name: serverCategory.name,
                color: serverCategory.color,
                colorDark: serverCategory.colorDark,
                order: serverCategory.order,
                created: serverCategory.created,
                updated: serverCategory.updated,
                deleted: localCategory.deleted,
              };
            }
          },
        )
        .filter(
          (
            category: ListItemCategoryInternalModel | undefined,
          ): category is ListItemCategoryInternalModel => category !== undefined,
        ),
    );
  }

  private static async updateServerCategories(
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverCategories.map(async (serverCategory: CategoryDto): Promise<void> => {
        const localCategory: ListItemCategoryInternalModel | undefined =
          localCategories.find(
            (localCategory: ListItemCategoryInternalModel): boolean =>
              localCategory.id === serverCategory.id,
          );
        if (!localCategory?.updated || !serverCategory.updated) {
          return;
        }
        const serverCategoryUpdated: number = new Date(serverCategory.updated).valueOf();
        const localCategoryUpdated: number = new Date(localCategory.updated).valueOf();
        const isLocalCategoryNewer: boolean =
          localCategoryUpdated - serverCategoryUpdated > 0;

        if (isLocalCategoryNewer && !localCategory.deleted) {
          if (serverCategory.isCustom) {
            await UserCategoriesService.putApiUserCategories(serverCategory.id, {
              name: localCategory.name,
              order: localCategory.order,
              color: localCategory.color,
              colorDark: localCategory.color,
            });
          } else {
            const overrideResult =
              await UserCategoriesService.putApiUserCategoriesOverride(
                serverCategory.id,
                {
                  name: localCategory.name,
                  order: localCategory.order,
                  color: localCategory.color,
                  colorDark: localCategory.color,
                },
              );
            await InternalStorageCategoriesService.addOrUpdateCategories([
              { ...localCategory, id: overrideResult.id },
            ]);
          }
        }
      }),
    );
  }

  private static async syncCategories(): Promise<void> {
    const localCategories: ListItemCategoryInternalModel[] =
      await InternalStorageCategoriesService.getCategories();
    const isSignedIn = this.checkSignIn && this.checkSignIn();
    if (isSignedIn) {
      const serverCategories = await UserCategoriesService.getApiUserCategories();
      await this.saveServerCategoriesToLocal(serverCategories, localCategories);
      await this.postLocalCategoriesToServer(localCategories);
      await this.updateServerCategories(serverCategories, localCategories);
      await this.updateLocalCategories(serverCategories, localCategories);
      await this.deleteServerCategories(serverCategories, localCategories);
      await this.deleteLocalCategories(serverCategories, localCategories);
    } else {
      const serverCategories = await CategoriesService.getApiCategories();
      await this.saveServerCategoriesToLocal(serverCategories, localCategories);
    }
  }

  private static async isFrontendChanged(): Promise<boolean> {
    const frontendLastChange =
      ((await InternalStorageCommonService.getValue(
        COMMON_STORE_KEYS.CATEGORIES_LAST_CHANGE,
      )) as string | undefined) ?? new Date(0).toISOString();
    const lastSync =
      ((await InternalStorageCommonService.getValue(
        COMMON_STORE_KEYS.CATEGORIES_LAST_SYNC,
      )) as string | undefined) ?? new Date(0).toISOString();
    return getDateValue(frontendLastChange) > getDateValue(lastSync);
  }

  private static async isBackendChanged(): Promise<boolean> {
    const backendLastChange = await LastChangesService.getApiChanges(2);
    const lastSync =
      ((await InternalStorageCommonService.getValue(
        COMMON_STORE_KEYS.CATEGORIES_LAST_SYNC,
      )) as string | undefined) ?? new Date(0).toISOString();
    return getDateValue(`${backendLastChange}Z`) > getDateValue(lastSync);
  }

  public static enqueue(): void {
    this.sequentialTaskRunner.enqueue(async () => {
      const shouldSync =
        (await this.isFrontendChanged()) || (await this.isBackendChanged());
      if (shouldSync) {
        console.time("Categories synced");
        console.log("Categories synchronization is running...");
        const beforeSyncTime = new Date().toISOString();
        await this.syncCategories();
        const frontendLastChange =
          ((await InternalStorageCommonService.getValue(
            COMMON_STORE_KEYS.CATEGORIES_LAST_CHANGE,
          )) as string | undefined) ?? new Date(0).toISOString();
        const timeToSet =
          getDateValue(frontendLastChange) > getDateValue(beforeSyncTime)
            ? beforeSyncTime
            : new Date().toISOString();
        await InternalStorageCommonService.addOrUpdateValue(
          COMMON_STORE_KEYS.CATEGORIES_LAST_SYNC,
          timeToSet,
        );
        LastChangesService.postApiChanges({ entity: 2, dateTime: timeToSet });
        if (this.updateStore) {
          this.updateStore();
        }
        console.timeEnd("Categories synced");
      }
    });
  }

  public static run(
    interval: number,
    updateStore: () => void,
    checkSignIn: () => boolean,
  ): void {
    if (!this.intervalId) {
      this.updateStore = updateStore;
      this.checkSignIn = checkSignIn;
      this.intervalId = setInterval(() => this.enqueue(), interval);
    }
  }

  public static stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.sequentialTaskRunner.clearQueue();
    }
  }
}
